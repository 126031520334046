<template>
  <vs-card>
    <div slot="header">
      <h1>Resource Hub - Json Setting</h1>
    </div>
    <div class="h-auto w-screen">
      <el-input
        id="jsonSettings"
        class="my-5 max-w-xl border-black"
        type="textarea"
        autosize
        :rows="1"
        v-model="value"
      >
      </el-input>
      <br />
      <vs-button @click="save">Update</vs-button>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";

const DEFAULT_TEMPLATE = {
  "main_banner": {
    "header": "",
    "subHeader": "",
    "image": ""
  },
  "first_section": {
    "header": "",
    "tag": ""
  },
  "document_icon": "",
  "popular_category_text": "",
  "recent_resources_text": "",
  "popular_categories": [
    {
      "label": "",
      "value": "",
      "icon": ""
    },
  ],
  "supplier_training": {
    "desktop_image": "",
    "mobile_image": "",
    "link": "",
  }
}

export default {
  data() {
    return {
      value: "",
      key: "resourceHubSetting",
    };
  },
  methods: {
    ...mapActions("admin", ["getSettingsByKey", "updateSettingsByKey"]),
    async save() {
      try {
        this.$vs.loading()

        await this.updateSettingsByKey({
          value: JSON.stringify(JSON.parse(this.value)),
          key: this.key
        })

        this.$vs.notify({
          title: "Success",
          text: "Settings Saved.",
          color: "success",
        });
      } catch (error) {
        console.log(`Error updating resource hub setting: ${error}`)
        this.$vs.notify({
          title: "Failed to save settings.",
          text: "Please try again later.",
          color: "danger",
        });
      } finally {
        this.$vs.loading.close()
      }
    },

    async fetchSetting() {
      try {
        this.$vs.loading()

        const { data } = await this.getSettingsByKey(this.key)
        let defaultValue = DEFAULT_TEMPLATE

        if (Object.keys(JSON.parse(data.data.value)).length) {
          defaultValue = JSON.parse(data.data.value)
        }

        this.value = JSON.stringify(defaultValue, null, 2);
      } catch (error) {
        console.log(`Error fetching resource hub setting: ${error}`)
      } finally {
        this.$vs.loading.close()
      }
    }
  },
  created() {
    this.fetchSetting()
  },
}
</script>

<style lang="scss">
.el-input {
  width: 400px;
}

.el-textarea__inner:focus {
  border-color: black !important;
}
</style>
